import '../sass/main.scss'
import { CookieConsent } from 'cookieconsent';

document.addEventListener('DOMContentLoaded', function () {
    cookieconsent.initialise({
        'palette': {
            'popup': {
                'background': '#2d383c',
                'text': '#a1a4a7'
            },
            'button': {
                'background': '#39ace7',
                'text': '#f6f6f6'
            }
        },
        'theme': 'classic',
        'content': {
          'dismiss': 'OK'
        }
    });
});